export const testimonalsInfo = [
  {
    starIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        viewBox="0 0 24 24"
        style={{
          userSelect: "none",
          width: "100%",
          height: "100%",
          display: "inline-block",
          fill: "var(--token-b62c5eed-f783-46cd-9d4c-7561401116f9, rgb(226, 235, 237))",
          flexShrink: 0,
        }}
      >
        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
      </svg>
    ),
    review:
      "Coupling Arhasi’s AI frameworks and tools is the accelerator pedal for rapid AI adoption. The LifeWorks AI employee assistant offers our employees a superior alternative for policy and procedure guidance.",
    image:
      "https://framerusercontent.com/images/mj4tu1XdsW994hmCinylNmJwis.jpg",
    name: "Wendy Varnell",
    role: "CSO, LifeWorks",
    logo: "https://framerusercontent.com/images/hQR8FfhSIlkH1EmP18eNWCBaJkU.png?scale-down-to=2048",
  },

  // {
  //   starIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       focusable="false"
  //       viewBox="0 0 24 24"
  //       style={{
  //         userSelect: "none",
  //         width: "100%",
  //         height: "100%",
  //         display: "inline-block",
  //         fill: "var(--token-b62c5eed-f783-46cd-9d4c-7561401116f9, rgb(226, 235, 237))",
  //         flexShrink: 0,
  //       }}
  //     >
  //       <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
  //     </svg>
  //   ),
  //   review:
  //     "As the CEO of an AI platform startup, the rapid development, expertise, and quality of our AI solutions are crucial for a successful launch. Partnering with Cloudbench has surpassed all my expectations. Their meticulous attention to detail, professionalism, and unparalleled expertise have been outstanding. They are a true innovation partner to our firm, and I highly recommend Cloudbench for anyone seeking to develop a generative AI solution for their business.",
  //   image:
  //     "https://framerusercontent.com/images/o7DapGo0w1SJx44zAnzYEbUhTE.webp",
  //   name: "Jeff Maser",
  //   role: "Founder & CEO, MarketMind",
  //   logo: "https://framerusercontent.com/images/PhrTK7FTH9DrndbOwmFrfiA5E.png",
  // },

  // {
  //   starIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       focusable="false"
  //       viewBox="0 0 24 24"
  //       style={{
  //         userSelect: "none",
  //         width: "100%",
  //         height: "100%",
  //         display: "inline-block",
  //         fill: "var(--token-b62c5eed-f783-46cd-9d4c-7561401116f9, rgb(226, 235, 237))",
  //         flexShrink: 0,
  //       }}
  //     >
  //       <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
  //     </svg>
  //   ),
  //   review:
  //     "Arhasi’s meticulous approach to our discovery phase, led by their exceptional strategy consultants, proved invaluable. Their expertise shone through every stage, from insightful user journey workshops to detailed wireframes and a compelling final presentation.  We're now confidently equipped to embark on a successful proof of concept for our AI dashboard platform",
  //   image:
  //     "https://framerusercontent.com/images/IEDdPvEJDHI3Zy0O1A35AsS7fQU.jpeg",
  //   name: "Wes Levitt",
  //   role: "CFA & co-CIO, Alpha Transform Holdings",
  //   logo: "",
  // },

  // {
  //   starIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       focusable="false"
  //       viewBox="0 0 24 24"
  //       style={{
  //         userSelect: "none",
  //         width: "100%",
  //         height: "100%",
  //         display: "inline-block",
  //         fill: "var(--token-b62c5eed-f783-46cd-9d4c-7561401116f9, rgb(226, 235, 237))",
  //         flexShrink: 0,
  //       }}
  //     >
  //       <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
  //     </svg>
  //   ),
  //   review:
  //     "With our partners Google and Arhasi, we're poised to assist startups and midmarket enterprises in navigating the intricate landscapes of technology and data, thereby enabling them to innovate more effectively.",
  //   image:
  //     "https://framerusercontent.com/images/UUPb5Oi6qT5CPuTkJuaDuZUf2uo.webp",
  //   name: "Tom Richer",
  //   role: "CEO at Cloudbench",
  //   logo: "https://framerusercontent.com/images/iwpgpF7jQ0h59VhM5xidKEcTqJo.jpg",
  // },
];

export const carouselImages = [
  "https://framerusercontent.com/images/AviIsCjmPCoKQ7IiZxsZSp1FNF0.webp",
  "https://framerusercontent.com/images/2j6xf0Og0MKA8hUZyxLE0jE5hEM.webp",
  "https://framerusercontent.com/images/dPXsVOxglU0auviYioVFJupSXdw.webp",
];
